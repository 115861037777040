import { Checkbox, FormLayout, LegacyStack, Select, TextField } from "@shopify/polaris";
import { SellingPlanPricingPolicyAdjustmentType } from "@smartrr/shared/shopifyGraphQL/api";
import { clamp } from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";

import { NumericTextField } from "@vendor-app/app/_sharedComponents/NumericTextField";

import { PricingPolicyModel } from "../models";

const AdjustmentTypeOptions: {
  label: string;
  value: string | SellingPlanPricingPolicyAdjustmentType;
}[] = [
  { label: "Fixed discount", value: SellingPlanPricingPolicyAdjustmentType.FixedAmount },
  { label: "Percentage discount", value: SellingPlanPricingPolicyAdjustmentType.Percentage },
  { label: "Discounted price", value: SellingPlanPricingPolicyAdjustmentType.Price },
];

interface Props {
  sellingPlanNumber?: number;
  policy: PricingPolicyModel;

  onChangePolicy(policy: PricingPolicyModel): void;
}

const DiscountOrderNumberContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
`;

export function PricingPolicyForm({ sellingPlanNumber = 0, policy, onChangePolicy }: Props): JSX.Element {
  const { adjustmentType, adjustmentValue, afterCycle, isEnabled } = policy;
  const isPercentageAdjustmentType = adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage;

  useEffect(() => {
    const modifiedValue = clamp(adjustmentValue, 0, isPercentageAdjustmentType ? 100 : Number.POSITIVE_INFINITY);
    if (modifiedValue !== adjustmentValue) {
      onChangePolicy({ ...policy, adjustmentValue: modifiedValue });
    }
  }, [isPercentageAdjustmentType]);

  return (
    <React.Fragment>
      {afterCycle !== 0 && (
        <FormLayout>
          <DiscountOrderNumberContainer>
            <Checkbox
              id={`pricing-policy__discount-incentive__change-discount-after-order_${sellingPlanNumber}`}
              label="Change discount after order #"
              checked={isEnabled}
              onChange={checked => onChangePolicy({ ...policy, isEnabled: checked })}
            />
          </DiscountOrderNumberContainer>
        </FormLayout>
      )}
      {isEnabled ? (
        <FormLayout>
          <FormLayout.Group condensed>
            <LegacyStack vertical>
              {afterCycle !== 0 && (
                <TextField
                  id={`pricing-policy__discount-incentive__order-number_${sellingPlanNumber}`}
                  autoComplete="off"
                  label="change discount after order number"
                  labelHidden={true}
                  disabled={!isEnabled}
                  type="number"
                  value={isEnabled ? policy.afterCycle.toString() : ""}
                  min={1}
                  onChange={value => {
                    const newValue = parseInt(value, 10);
                    if (newValue >= 1) {
                      onChangePolicy({
                        ...policy,
                        afterCycle: newValue,
                      });
                    }
                  }}
                />
              )}
              <Select
                id={`pricing-policy__discount-incentive__change-discount-after-order_${
                  afterCycle !== 0
                }__discount-type_${sellingPlanNumber}`}
                label="adjustment policy type"
                labelHidden={true}
                disabled={!isEnabled}
                options={AdjustmentTypeOptions}
                value={adjustmentType}
                onChange={value => {
                  const updates = {
                    adjustmentType: value as SellingPlanPricingPolicyAdjustmentType,
                  } as Partial<PricingPolicyModel>;

                  if (value === SellingPlanPricingPolicyAdjustmentType.Percentage) {
                    updates.adjustmentValue = 0;
                  }

                  onChangePolicy({
                    ...policy,
                    ...updates,
                  });
                }}
              />
            </LegacyStack>
            <NumericTextField>
              <TextField
                id={`pricing-policy__discount-incentive__change-discount-after-order_${
                  afterCycle !== 0
                }__discount_${sellingPlanNumber}`}
                autoComplete="off"
                label="adjustment value"
                labelHidden={true}
                disabled={!isEnabled}
                min={0}
                type="number"
                value={adjustmentValue.toString()}
                prefix={isPercentageAdjustmentType ? "%" : "$"}
                onChange={value => {
                  const newValue = isPercentageAdjustmentType ? parseInt(value, 10) : moneyStringToNumber(value);
                  if (newValue >= 0 && (!isPercentageAdjustmentType || newValue <= 100)) {
                    onChangePolicy({
                      ...policy,
                      adjustmentValue: newValue,
                    });
                  }
                }}
              />
            </NumericTextField>
          </FormLayout.Group>
        </FormLayout>
      ) : null}
    </React.Fragment>
  );
}

/**
 * Converts a string representing money with two decimal places to a number.
 * Avoids floating point rounding errors found in solutions using parseFloat().
 */
function moneyStringToNumber(moneyString: string): number {
  // Get the decimal separator for the user's locale
  const decimalSeparator = (1.1).toLocaleString().charAt(1);

  // Remove unwanted characters that are allowed in a number input
  moneyString = moneyString.replaceAll(/[+=Ee]/g, "");

  const decimalIdx = moneyString.indexOf(decimalSeparator);

  // If there's no decimal point or only 1 digit after it, just return the number
  if (decimalIdx === -1 || moneyString.length - decimalIdx <= 3) {
    return Number(moneyString);
  }

  // Otherwise, truncate the decimal part at two digits and return the number
  return Number(moneyString.slice(0, decimalIdx + 3));
}
