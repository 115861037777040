import { ApolloClient } from "@apollo/client";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import {
  IEnabledShopifyProductIdToShopifyVariantIdMap,
  ISellingPlanFromZodWithId,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { paginatedRequestStreamShopifyGraphQL } from "@smartrr/shared/shopifyGraphQL/_utils";
import {
  CurrencyCode,
  GetSellingPlanGroupsQuery,
  SellingPlanCategory,
  SellingPlanFragmentFragment,
  SellingPlanGroupInput,
  SellingPlanInput,
  SellingPlanPricingPolicyInput,
} from "@smartrr/shared/shopifyGraphQL/api";
import {
  SellingPlanFromGetSellingPlanGroupQuery,
  SellingPlanGroupFromGetSellingPlanGroupQuery,
  queryShopifySellingPlanGroups,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { cloneDeep, isEqual, omit } from "lodash";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

import { ProductOfferingSettings } from "./models";
import { validateDataWithZodSchema } from "@smartrr/shared/utils/validateDataWithZodSchema";
import { z } from "zod";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { sortByDate } from "@smartrr/shared/utils/sortBy";
import { SmartrrSortType } from "@smartrr/shared/utils/paginatedQuery";
import { emptySmartrrSellingPlan } from "./constants";

// we extract the id off this later to update in request
export type SellingPlanGroupInputWithId = SellingPlanGroupInput & { id?: string };

export function sellingPlanToSellingPlanInput<T extends SellingPlanFragmentFragment>(
  sellingPlan: T
): SellingPlanInput {
  const { billingPolicy, deliveryPolicy } = sellingPlan;

  if (billingPolicy.__typename === "SellingPlanRecurringBillingPolicy") {
    if (deliveryPolicy.__typename === "SellingPlanRecurringDeliveryPolicy") {
      return {
        ...sellingPlan,
        id: sellingPlan.id || undefined,
        billingPolicy: {
          recurring: omit(
            {
              ...billingPolicy,
              anchors: (billingPolicy.anchors ?? []).map((_: any) => omit(_, "__typename")),
            },
            "__typename"
          ),
        },
        deliveryPolicy: {
          recurring: omit(
            {
              ...deliveryPolicy,
              anchors: (deliveryPolicy.anchors ?? []).map((_: any) => omit(_, "__typename")),
            },
            "__typename"
          ),
        },
        pricingPolicies: pricingPoliciesFromQueryToPricingPolicyInputs(sellingPlan.pricingPolicies),
      };
    }
    console.error(`Should never happen!!! 001`);
    throw new Error();
  } else {
    console.error(`Should never happen!!! 002`);
    throw new Error();
  }
}

export function formatSellingPlanGroupQueryResponse({
  sellingPlans,
  ...rest
}: SellingPlanGroupFromGetSellingPlanGroupQuery): {
  sellingPlanGroupInput: SellingPlanGroupInputWithId;
  sellingPlanInputs: SellingPlanInput[];
} {
  return {
    sellingPlanGroupInput: {
      id: rest.id,
      appId: rest.appId,
      description: rest.description,
      merchantCode: rest.merchantCode,
      name: rest.name,
      options: rest.options,
    },
    sellingPlanInputs: sellingPlans.edges.map(({ node }) => sellingPlanToSellingPlanInput(node)),
  };
}

export async function getSellingPlanGroups(client: ApolloClient<object>) {
  const groups: DeepExtractTypeSkipArrays<GetSellingPlanGroupsQuery, ["sellingPlanGroups", "edges", "node"]>[] =
    [];
  await paginatedRequestStreamShopifyGraphQL(
    groupCursor => queryShopifySellingPlanGroups(client, true, groupCursor),
    result => result.data?.sellingPlanGroups,
    async paginatedResult => {
      const nodes = paginatedResult.data?.sellingPlanGroups?.edges?.map(({ node }) => node);
      for (const node of nodes) {
        groups.push(node);
      }
    }
  );
  return groups;
}

export function determineSellingPlanOperations(
  sellingPlans: SellingPlanInput[],
  existingSellingPlans: SellingPlanInput[],
  category?: SellingPlanCategory | null
): {
  sellingPlansToCreate: SellingPlanInput[];
  sellingPlansToUpdate: SellingPlanInput[];
  sellingPlansToDelete: SellingPlanInput[];
} {
  const sellingPlansToCreate = sellingPlans
    .filter(p => !existingSellingPlans.find(node => node.id === p.id))
    .map(p => ({ ...omit(p, "__typename"), ...(category && { category }) }));
  const sellingPlansToUpdate = sellingPlans
    .filter(p => !!existingSellingPlans.find(node => node.id === p.id))
    .map(p => omit(p, "__typename"));
  const sellingPlansToDelete = existingSellingPlans.filter(node => !sellingPlans.find(p => p.id === node.id));

  return {
    sellingPlansToCreate,
    sellingPlansToUpdate,
    sellingPlansToDelete,
  };
}

export function hasAnyChanges(
  existingSellingPlanGroup: SellingPlanGroupInputWithId,
  existingSellingPlans: SellingPlanInput[],
  sellingPlanGroup: SellingPlanGroupInputWithId,
  sellingPlans: SellingPlanInput[]
): boolean {
  if (existingSellingPlans.length !== sellingPlans.length) {
    return true;
  }

  const isGroupEqual = isEqual(existingSellingPlanGroup, sellingPlanGroup);
  const arePlansEqual = sellingPlans.every((sp, index) => isEqual(existingSellingPlans[index], sp));

  return !isGroupEqual || !arePlansEqual;
}

export function didProductOfferingChange(initialSettings: any, currentValues: any) {
  return !isEqual(initialSettings, currentValues);
}

export function pricingPoliciesFromQueryToPricingPolicyInputs(
  pricingPolicies: SellingPlanFromGetSellingPlanGroupQuery["pricingPolicies"] = []
): SellingPlanPricingPolicyInput[] {
  return pricingPolicies.map(pricingPolicy => {
    const adjustmentValue =
      pricingPolicy.adjustmentValue.__typename === "MoneyV2"
        ? { fixedValue: pricingPolicy.adjustmentValue.amount }
        : pricingPolicy.adjustmentValue.__typename === "SellingPlanPricingPolicyPercentageValue"
          ? { percentage: pricingPolicy.adjustmentValue.percentage }
          : undefined;

    if (pricingPolicy.__typename === "SellingPlanFixedPricingPolicy") {
      return {
        fixed: {
          adjustmentType: pricingPolicy.adjustmentType,
          adjustmentValue,
        },
      };
    }

    if (pricingPolicy.__typename === "SellingPlanRecurringPricingPolicy") {
      return {
        recurring: {
          adjustmentType: pricingPolicy.adjustmentType,
          adjustmentValue,
          afterCycle: pricingPolicy.afterCycle || 1,
        },
      };
    }

    throw new TypeError(`Pricing policy "${pricingPolicy.__typename}" is not supported`);
  });
}

export function getVariantName(purchasable: IPurchasable, variant: IPurchasableVariant): string {
  return [purchasable.purchasableName, variant.purchasableVariantName].filter(Boolean).join(" - ");
}

export function getSelectedVariantName(
  allVariants: IPurchasableVariant[],
  variantToPurchasableMap: Record<string, IPurchasable>,
  id: string
) {
  const variant = allVariants.find(pr => pr.id === id);

  if (!variant) {
    return "";
  }

  return getVariantName(variantToPurchasableMap[variant.id], variant);
}

export async function isValidImageUrl(url: string): Promise<boolean> {
  const res = await fetch(url, { method: "HEAD" });
  return res.headers.get("Content-Type")?.startsWith("image") ?? false;
}

export const changeNumber = (
  newValue: string,
  setOrderNumber: (value: React.SetStateAction<number>) => void,
  productOfferingRef: React.MutableRefObject<ProductOfferingSettings>
) => {
  const regexpForNumberBiggerThanZero = /^\+?[1-9]\d*$/;
  if (regexpForNumberBiggerThanZero.test(newValue)) {
    setOrderNumber(+newValue);
    productOfferingRef.current.orderNumber = +newValue;
  } else {
    setOrderNumber(1);
    productOfferingRef.current.orderNumber = 1;
  }
};

export const createSmartrrSellingPlanCopy = (
  sellingPlan: ISellingPlanFromZodWithId
): ISellingPlanFromZodWithId => {
  const cleanedSellingPlan = cloneDeep(omit(sellingPlan, ["id", "shopifyId"]));
  return {
    ...cleanedSellingPlan,
    createdDate: new Date().toISOString(),
    cartCheckoutLabel: `Copy of ${sellingPlan.cartCheckoutLabel}`,
    displayLabel: `Copy of ${cleanedSellingPlan.displayLabel}`,
  };
};

export const createEmptySmartrrSellingPlan = (): ISellingPlanFromZodWithId => {
  const emptySellingPlan = cloneDeep(emptySmartrrSellingPlan);
  return {
    ...emptySellingPlan,
    createdDate: new Date().toISOString(),
  };
};

export const getAllGroupProductsIdsFromVariantMap = (
  groupProductIds: IEnabledShopifyProductIdToShopifyVariantIdMap
) => {
  const allIds: number[] = [];

  for (const id of Object.keys(groupProductIds)) {
    const result = validateDataWithZodSchema(z.number(), parseInt(id));

    if (result.success) {
      allIds.push(parseInt(id));
    }
  }

  return allIds;
};

const CurrencyCodeSchema = z.nativeEnum(CurrencyCode);

const ValueSchema = z.union([
  z
    .number({
      invalid_type_error: "Discount must be a number",
      required_error: "Discount amount is required",
    })
    .min(0, "Discount must be greater than or equal to 0"),
  CurrencyCodeSchema,
]);

export const validatePricingPolicyWithZod = (
  pricingPolicies: SellingPlanPricingPolicyInput[]
): {
  success: boolean;
  error?: string;
} => {
  for (const policy of pricingPolicies) {
    const result = validateDataWithZodSchema(z.record(z.string(), ValueSchema), policy.fixed?.adjustmentValue);
    if (!result.success) {
      const err = result.error.issues[0].message;
      return { success: false, error: err };
    }
  }
  return { success: true };
};

export const sortSellingPlansByCreatedDate = (
  sellingPlansToSort: ISellingPlanFromZodWithId[],
  order: SmartrrSortType = "ASC"
) => sortByDate(sellingPlansToSort, sp => ISODateString.fromString(sp.createdDate), order);
