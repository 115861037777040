import { Button, Collapsible, Icon, LegacyStack, ResourceItem, ResourceList, Thumbnail } from "@shopify/polaris";
import { CancelSmallMinor, ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { getVariantImageFromPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { flatten } from "lodash";
import React, { useState } from "react";

import {
  ChevronContainer,
  ProductAndVariantTitleContainer,
  ProductListItemProps,
  ResourceItemContainer,
  TitleForLinkedToProductVariants,
  VariantListItemProps,
  VariantOrProductTitle,
} from "../../constants";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import { StatusBadge } from "@vendor-app/app/_sharedComponents/StatusBadge";

export const SellingPlanGroupProductsListItem = ({
  purchasable,
  variantIds,
  variantToPurchasableMap,
  handleRemoveProductsAndVariants,
}: ProductListItemProps) => {
  const [open, setOpen] = useState(false);

  const variantNames = purchasable.vnts
    ?.filter(vnt => variantIds.includes(vnt.shopifyId!) && !isDefaultVariant(vnt.purchasableVariantName))
    .map(vnt => vnt.purchasableVariantName)
    .join(", ");

  return (
    <div data-testid="product-resource-item">
      <ResourceItem
        key={purchasable.shopifyId || ""}
        id={purchasable.shopifyId || ""}
        verticalAlignment="center"
        onClick={() => undefined}
      >
        <LegacyStack alignment="center" distribution="equalSpacing" wrap={false}>
          <div data-testid="product-resource-item-click-ele" onClick={() => setOpen(open => !open)}>
            <LegacyStack alignment="center" distribution="leading">
              <ChevronContainer>
                <Icon color="base" source={open ? ChevronUpMinor : ChevronDownMinor} />
                <Thumbnail
                  size="small"
                  source={purchasable.purchasableImages?.[0] ?? "Product"}
                  alt={purchasable.purchasableName}
                />
              </ChevronContainer>
              <ProductAndVariantTitleContainer>
                <VariantOrProductTitle variation="strong" title={purchasable.purchasableName}>
                  {purchasable.purchasableName}
                </VariantOrProductTitle>
                <VariantOrProductTitle title={variantNames}>{variantNames}</VariantOrProductTitle>
              </ProductAndVariantTitleContainer>
            </LegacyStack>
          </div>
          <LegacyStack distribution="equalSpacing" spacing="baseTight">
            <StatusBadge obj={purchasable} />
            <div data-testid="remove-item-btn">
              <Button
                onClick={() =>
                  handleRemoveProductsAndVariants(
                    purchasable.vnts?.map(vnt => vnt.shopifyId!) || [],
                    purchasable.shopifyId!
                  )
                }
                plain
                icon={CancelSmallMinor}
              />
            </div>
          </LegacyStack>
        </LegacyStack>
        <div data-testid="basic-collapsible">
          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            <ResourceList
              items={purchasable.vnts?.filter(vnt => variantIds.includes(vnt.shopifyId!)) || []}
              renderItem={variant => {
                const vntImage = getVariantImageFromPurchasableMap(variantToPurchasableMap, variant);
                const vntName = isDefaultVariant(variant.purchasableVariantName)
                  ? purchasable.purchasableName
                  : variant.purchasableVariantName ?? "";
                return (
                  <ResourceItemContainer>
                    <ResourceItem
                      key={variant.shopifyId || ""}
                      id={variant.shopifyId || ""}
                      verticalAlignment="center"
                      onClick={() => undefined}
                    >
                      <LegacyStack alignment="center" distribution="equalSpacing" wrap={false}>
                        <div onClick={() => setOpen(open => !open)} data-testid="product-resource-collapse-item">
                          <LegacyStack alignment="center" distribution="leading">
                            <Thumbnail size="small" source={vntImage ?? "Variant"} alt={vntName} />
                            <TitleForLinkedToProductVariants title={vntName}>
                              {vntName}
                            </TitleForLinkedToProductVariants>
                          </LegacyStack>
                        </div>
                        <LegacyStack distribution="equalSpacing" spacing="baseTight">
                          <StatusBadge obj={variant} />
                          <div data-testid="remove-item-btn">
                            <Button
                              onClick={() =>
                                handleRemoveProductsAndVariants([variant.shopifyId!], purchasable.shopifyId)
                              }
                              plain
                              icon={CancelSmallMinor}
                            />
                          </div>
                        </LegacyStack>
                      </LegacyStack>
                    </ResourceItem>
                  </ResourceItemContainer>
                );
              }}
            />
          </Collapsible>
        </div>
      </ResourceItem>
    </div>
  );
};

export const SellingPlanGroupVariantToPurchaseableListItem = ({
  variant,
  variantToPurchasableMap,
  handleRemoveProductsAndVariants,
  linkedProducts,
  index,
}: VariantListItemProps) => {
  const vntImage = getVariantImageFromPurchasableMap(variantToPurchasableMap, variant);
  const displayName = formatProductAndVariantNameForDisplay({
    purchasableName: variant.purchasableName,
    purchasableVariantName: variant.purchasableVariantName,
    isActive: variant.isActiveInShopify,
  });

  return (
    <ResourceItem
      key={variant.shopifyId || ""}
      id={variant.shopifyId || ""}
      verticalAlignment="center"
      onClick={() => undefined}
    >
      <LegacyStack alignment="center" distribution="equalSpacing" wrap={false}>
        <LegacyStack alignment="center" distribution="leading">
          <span>{flatten(linkedProducts.map(p => p.vnts)).length + index + 1}.</span>
          <Thumbnail size="small" source={vntImage ?? "Variant"} alt={variant?.purchasableVariantName || ""} />
          <VariantOrProductTitle variation="strong" title={displayName}>
            {displayName}
          </VariantOrProductTitle>
        </LegacyStack>
        <LegacyStack distribution="equalSpacing" spacing="baseTight">
          <StatusBadge obj={variant} />
          <div data-testid="remove-item-btn">
            <Button
              onClick={() => handleRemoveProductsAndVariants([variant.shopifyId!])}
              plain
              icon={CancelSmallMinor}
            />
          </div>
        </LegacyStack>
      </LegacyStack>
    </ResourceItem>
  );
};
