import React from "react";

export const AddProductMajorSVG = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9998 3.00005H53.9998C54.7955 3.00005 55.5585 3.31612 56.1211 3.87873C56.6837 4.44134 56.9998 5.2044 56.9998 6.00005V27.0001C57.0021 27.3949 56.9264 27.7863 56.7771 28.1518C56.6278 28.5173 56.4079 28.8497 56.1298 29.1301L55.2598 30.0001H47.9998C43.2259 30.0001 38.6476 31.8965 35.2719 35.2721C31.8962 38.6478 29.9998 43.2262 29.9998 48.0001V55.2601L29.1298 56.1301C28.8509 56.4112 28.5191 56.6344 28.1535 56.7867C27.788 56.939 27.3959 57.0174 26.9998 57.0174C26.6038 57.0174 26.2117 56.939 25.8461 56.7867C25.4805 56.6344 25.1487 56.4112 24.8698 56.1301L3.86982 35.1301C3.58863 34.8512 3.36545 34.5194 3.21314 34.1538C3.06084 33.7882 2.98242 33.3961 2.98242 33.0001C2.98242 32.604 3.06084 32.2119 3.21314 31.8463C3.36545 31.4807 3.58863 31.1489 3.86982 30.8701L30.8698 3.87005C31.1501 3.59201 31.4826 3.37203 31.8481 3.22274C32.2136 3.07344 32.605 2.99777 32.9998 3.00005ZM43.9998 17.2417C44.7398 17.7361 45.6098 18 46.4998 18C47.6933 18 48.8379 17.5259 49.6818 16.682C50.5257 15.8381 50.9998 14.6935 50.9998 13.5C50.9998 12.61 50.7359 11.74 50.2414 11C49.747 10.26 49.0442 9.68319 48.2219 9.34259C47.3996 9.002 46.4948 8.91288 45.6219 9.08652C44.749 9.26015 43.9472 9.68873 43.3178 10.3181C42.6885 10.9474 42.2599 11.7492 42.0863 12.6221C41.9127 13.4951 42.0018 14.3999 42.3424 15.2221C42.683 16.0444 43.2597 16.7472 43.9998 17.2417Z"
        fill="#8C9196"
      />
      <path
        d="M59.1213 45.8787C58.5587 45.3161 57.7957 45 57 45H51V39C51 38.2043 50.6839 37.4413 50.1213 36.8787C49.5587 36.3161 48.7957 36 48 36C47.2043 36 46.4413 36.3161 45.8787 36.8787C45.3161 37.4413 45 38.2043 45 39V45H39C38.2043 45 37.4413 45.3161 36.8787 45.8787C36.3161 46.4413 36 47.2043 36 48C36 48.7957 36.3161 49.5587 36.8787 50.1213C37.4413 50.6839 38.2043 51 39 51H45V57C45 57.7957 45.3161 58.5587 45.8787 59.1213C46.4413 59.6839 47.2043 60 48 60C48.7957 60 49.5587 59.6839 50.1213 59.1213C50.6839 58.5587 51 57.7957 51 57V51H57C57.7957 51 58.5587 50.6839 59.1213 50.1213C59.6839 49.5587 60 48.7957 60 48C60 47.2043 59.6839 46.4413 59.1213 45.8787Z"
        fill="#8C9196"
      />
    </svg>
  );
};
