import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import {
  ISellingPlanFromZodWithId,
  ISellingPlanPricingPolicyFromZod,
} from "@smartrr/shared/entities/SellingPlanGroup";
import {
  SellingPlanInput,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
} from "@smartrr/shared/shopifyGraphQL/api";
import React from "react";

import { PricingPolicyForm } from "./PricingPolicyForm";
import { AuthorizedControl } from "../../components/authorization/AuthorizedControl";
import {
  PricingPolicyModel,
  PricingPolicyType,
  buildModelFromPricingPolicy,
  buildModelFromSmartrrPricingPolicy,
  buildPricingPoliciesFromModels,
  buildSmartrrPricingPoliciesFromModels,
} from "../models";

interface Props {
  sellingPlanNumber?: number;
  sellingPlan: ISellingPlanFromZodWithId | SellingPlanInput;
  fixedPolicyOnly?: boolean; // If set to true, hides recurring policy
  onPoliciesUpdate(
    pricingPolicies: Array<SellingPlanPricingPolicyInput | ISellingPlanPricingPolicyFromZod>
  ): void;
}

const isSmartrrSellingPlan = (x: any): x is ISellingPlanFromZodWithId => x.hasOwnProperty("cartCheckoutLabel");

export function PricingPolicies({
  sellingPlanNumber = 0,
  sellingPlan,
  fixedPolicyOnly = false,
  onPoliciesUpdate,
}: Props): JSX.Element {
  const policies = isSmartrrSellingPlan(sellingPlan)
    ? sellingPlan.pricingPolicies?.map(buildModelFromSmartrrPricingPolicy) || []
    : sellingPlan.pricingPolicies?.map(buildModelFromPricingPolicy) || [];

  const fixedPolicy = policies[0] || {
    policyType: PricingPolicyType.Fixed,
    adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
    adjustmentValue: 0,
    afterCycle: 0,
    isEnabled: true,
  };

  const recurringPolicy = policies[1] || {
    policyType: PricingPolicyType.Recurring,
    adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
    adjustmentValue: 0,
    afterCycle: 1,
    isEnabled: false, // By default recurring policy is disabled
  };
  if (fixedPolicyOnly) {
    // If we display form in mode for fixed policy only, then recurring policy (if it existed and was enabled)
    // should not be saved in the end
    recurringPolicy.isEnabled = false;
  }

  const buildPolicy = (updatedPolicy: PricingPolicyModel, existingPolicy: PricingPolicyModel) => {
    if (isSmartrrSellingPlan(sellingPlan)) {
      return buildSmartrrPricingPoliciesFromModels([updatedPolicy, existingPolicy]);
    }
    return buildPricingPoliciesFromModels([updatedPolicy, existingPolicy]);
  };

  return (
    <React.Fragment>
      <PricingPolicyForm
        sellingPlanNumber={sellingPlanNumber}
        policy={fixedPolicy}
        onChangePolicy={updatedPolicy => onPoliciesUpdate(buildPolicy(updatedPolicy, recurringPolicy))}
      />
      {!fixedPolicyOnly && (
        <AuthorizedControl feature={FeatureEnum.SEQUENTIAL_SELLING_PLAN}>
          <PricingPolicyForm
            sellingPlanNumber={sellingPlanNumber}
            policy={recurringPolicy}
            onChangePolicy={updatedPolicy => onPoliciesUpdate(buildPolicy(fixedPolicy, updatedPolicy))}
          />
        </AuthorizedControl>
      )}
    </React.Fragment>
  );
}
