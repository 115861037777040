import styled from "styled-components";

export const NoProductsContainer = styled.div`
  padding: 4rem 1rem;
  text-align: center;
`;

export const NotAvaialableContainer = styled.div`
  display: flex;
`;

export const NotAvaialableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const VariantOrProductTitle = styled.span.attrs((props: { variation: "strong" }) => ({ ...props }))`
  display: block;
  font-weight: ${props => (props.variation === "strong" ? "bold" : "normal")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  @media only screen and (max-width: 880px) {
    width: 265px;
  }
  @media only screen and (max-width: 830px) {
    width: 28vw;
  }
  @media only screen and (max-width: 780px) {
    width: 24vw;
  }
  @media only screen and (max-width: 768px) {
    width: 42vw;
  }
  @media only screen and (max-width: 550px) {
    width: 26vw;
  }
`;

export const ProductAndVariantTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkWrapper = styled.div`
  .Polaris-Link {
    display: flex;
    font-size: 12px;
  }
`;

export const CardTitle = styled.div`
  .Polaris-Text--headingXs {
    color: #6d7175;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
`;

export const ChevronContainer = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 0.5rem;
  }
`;

export const PaginationContainer = styled.div`
  & > div {
    & > nav {
      > div {
        display: flex;
        justify-content: space-between;

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }
`;

export const TitleForLinkedToProductVariants = styled.span`
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 270px;
  @media only screen and (max-width: 880px) {
    width: 225px;
  }
  @media only screen and (max-width: 830px) {
    width: 24vw;
  }
  @media only screen and (max-width: 780px) {
    width: 20vw;
  }
  @media only screen and (max-width: 750px) {
    width: 38vw;
  }
  @media only screen and (max-width: 555px) {
    width: 22vw;
  }
`;

export const ResourceItemContainer = styled.div`
  & .Polaris-ResourceItem__Container {
    padding-left: 40px;
    padding-right: 0;
  }
`;
